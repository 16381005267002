import React, { useState, useRef, useEffect } from 'react';
import Footer from './Footer';
import Toast from './Toast';



const Offer = ({ t }) => {
  const interestOptions = [
    { value: 'What are you interested in?', label: t('whatAreYouIntrestIn') },
    { value: 'Immersive Animations', label: t('section3.header') },
    { value: 'Interactive Virtual Tours', label: t('section5.header') },
    { value: 'Photorealistic Renderings', label: t('section6.header') },
    { value: 'Your Own Apps', label: t('section7.header') },
    { value: 'All of the above', label: t('allAbove') },
  ];
  const initialData = {
    firstName: '',
    lastName: '',
    postcode: '',
    email: '',
    updates: '',
    mobile: '',
    interest: '',
    comments: '',
    company: ''
  }
  const dropdownRef = useRef(null);

  const [formData, setFormData] = useState(initialData);
  const [showToast, setShowToast] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (option) => {
    setFormData({ ...formData, interest: option.label })
    setIsOpen(false);
  };

  const handleShowToast = () => {
    setShowToast(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const throttle = (func, limit) => {
    let inThrottle;
    return function (...args) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => inThrottle = false, limit);
      }
    };
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedData = {
      'First Name': formData.firstName,
      'Last Name': formData.lastName,
      'Postcode': formData.postcode,
      'Email': formData.email,
      'Mobile': formData.mobile,
      'Company': formData.company,
      'What are you interested in?': formData.interest,
      'Comments': formData.comments,
      'Get Updates': formData.updates
    };
    try {
      const response = await fetch('https://bi02xt6qqf.execute-api.ap-southeast-2.amazonaws.com/Metaluxe-landing/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
      });

      await response.json();
      handleShowToast(true)
      setFormData(initialData)
    } catch (error) {
      console.log(error)
    }
  };
  const throttledHandleSubmit = throttle(handleSubmit, 5000);

  return (
    <div className="bg-cream">
      {showToast &&
        <Toast
          message={t('offerSumbitMessage')}
          type="success"
          t={t}
          onClose={() => setShowToast(false)}
        />
      }
      <div className="max-w-4xl mx-auto pt-24 pb-0 px-4 md:px-8">
        <div className="m-auto w-20 h-20 bg-gray-1 rounded-full flex items-center justify-center font-bold shadow-lg text-center z-[999]">
          <div className="drop-shadow-lg relative">
            <img src="/icons/offer.png" alt="offer"
              className="p-1 opacity-0 animate-[imageSwap_4s_infinite] absolute top-[-12px]"
            />
            <img src="/icons/render-offer.png" alt="offer" className="object-cover opacity-0 animate-[imageSwapReverse_4s_infinite]" />
          </div>
        </div>
        <div className='bg-black text-white border py-2 shadow-md transition-colors duration-300 rounded-bl-3xl rounded-tr-3xl mt-8 text-center text-base md:text-xl tracking-wider font-bold'>{t('offer.title')}</div>
        <p className='text-lg md:text-xl tracking-wider mt-8 mb-6 text-center text-gray-2 font-bold'>
          {t('offer.text1')}
        </p>
        <p className='text-lg md:text-xl tracking-wider mb-8 text-center text-gray-2 font-bold'>
          <span dangerouslySetInnerHTML={{ __html: t('offer.text2') }} />
        </p>
        <form onSubmit={throttledHandleSubmit} className="border-black border-t-2 pt-4">
          {/* First and Last Name */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-3">
            <div>
              <input
                type="text"
                name="firstName"
                placeholder={`${t("firstName")} *`}
                className="w-full border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.firstName}
                onChange={handleChange}
                required
              />
            </div>
            <div className='mb-3'>
              <input
                type="text"
                name="lastName"
                placeholder={`${t("lastName")} *`}
                className="w-full border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.lastName}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          {/* Postcode and Email */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-3">
            <div className='mb-3'>
              <input
                type="email"
                name="email"
                placeholder={`${t("email")} *`}
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <input
                type="text"
                name="postcode"
                placeholder={`${t("postcode")} *`}
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.postcode}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-3">
            <div>
              <input
                type="text"
                name="mobile"
                placeholder={`${t("mobile")} *`}
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <input
                type="text"
                name="company"
                placeholder={t('company')}
                className="w-full border-b border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                value={formData.company}
                onChange={handleChange}
              />
            </div>
          </div>

          <div ref={dropdownRef}>
            <div className="relative mb-3">
              <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="w-full text-left border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
                aria-haspopup="listbox"
                aria-expanded={isOpen}
                aria-labelledby="listbox-label"
              >
                <span className="block truncate">{formData.interest || t('whatAreYouIntrestIn')}</span>

              </button>

              {isOpen && (
                <ul
                  className="absolute z-10 mt-1 max-h-56  w-full overflow-auto rounded-md bg-[#5a5a58] py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  role="listbox"
                  aria-labelledby="listbox-label"
                >
                  {interestOptions.map((option, index) => (
                    <li
                      key={option.label}
                      className={`relative cursor-default ${index === 0 ? 'hover:bg-gray-500' : 'hover:bg-[#4584e9]'}  select-none py-1 pl-3 pr-9 text-gray-900`}
                      onClick={() => index !== 0 && handleSelect(option)}
                      role="option"
                      aria-selected={formData.interest === option.label}
                    >

                      <span
                        className={`ml-3 block truncate font-light  text-base ${index === 0 ? 'text-gray-900' : 'text-white'}`}>
                        {(index !== 0 && formData.interest === option.label) && <span style={{ position: 'absolute', left: '8px' }}>✓</span>}	 {option.label}
                      </span>

                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>

          {/* Comments */}
          <div className="mt-3">
            <textarea
              name="comments"
              placeholder={t('comments')}
              className="w-full  border-black bg-transparent focus:outline-none border-b-2 py-2 placeholder-black "
              value={formData.comments}
              onChange={handleChange}
              rows="1" cols="50"
            ></textarea>
          </div>
          <div className="flex flex-col">
            {/* Checkbox */}
            <div className="flex items-center md:items-start text-center m-auto md:px-48 text-black  ml-0 md:ml-2">
              <input
                type="checkbox"
                name="updates"
                className="w-8 h-8 bg-cream"
                checked={formData.updates}
                onChange={handleChange}
                id="agree"
              />
              <label htmlFor="agree" >
                <div className="text-p-xxs w-full " style={{ textAlign: 'left' }}>
                  <div dangerouslySetInnerHTML={{ __html: t('acceptTerms') }} />
                </div>
              </label>
            </div>
          </div>
          {/* Submit Button */}
          <div className='w-100 text-center'>
            <button
              type="submit"
              className="text-black  mb-24 px-3 text-lg font-semibold border-black border-b-2 pt-4 tracking-wider capitalize"
            >
              {t('submitOffer')}
            </button>
          </div>
        </form>
      </div>
      <Footer t={t} />
    </div>
  );
};

export default Offer;
