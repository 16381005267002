import React, { useState, useEffect } from "react";
import ImageCarousel from "./ImageCarousel";

const renderImages = [
  'render1.jpeg', 'render2.jpeg', 'render3.jpeg', 'render4.jpeg', 'render5.jpeg', 'render6.jpeg',
  'render7.jpeg', 'render8.jpeg', 'render9.jpeg', 'render10.jpeg', 'render12.jpeg',
  'render14.jpeg', 'render15.jpeg', 'render16.jpeg', 'render17.jpeg', 'render18.jpeg',
  'render19.png', 'render20.png', 'render21.png', 'render22.png', 'render23.png', 'render24.png',
  'render25.png', 'render26.png', 'render27.png', 'render28.png',
  'render31.png', 'render33.jpeg', 'render34.jpeg', 'render35.jpeg', 'render36.png',
  'render37.png', 'render38.png', 'render39.png', 'render40.jpeg', 'render41.jpeg', 'render42.jpeg',
  'render43.jpeg', 'render44.jpeg', 'render45.jpeg', 'render46.jpeg'
]

const Renders = ({ show, t, scrollToNextSection, setIsRenderOpen }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setIsVisible(true), 80);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <div className={`fade-in-section relative text-center ${isVisible ? 'show' : ''}`}>
      <h1 className='header text-2xl md:text-4xl text-black tracking-widest uppercase'>{t('section6.header')}</h1>
      <div className='section-entry'>
        <p className="text-p-s px-0 pt-10 pb-12 md:px-8">
          {t('section6.description')}
        </p>
      </div>

      {/* <Link to="/offer" reloadDocument className="btn rounded-bl-xl rounded-tr-xl" id='renders'>
        ENQUIRE NOW
      </Link> */}

      <div className='carousel-full-screen' id='renders'>
        <ImageCarousel images={renderImages} slideCount={1} >
          {renderImages.map((image, index) => (
            <div key={index} className='flex-center' onClick={setIsRenderOpen}>
              <img src={`/images/renders/${image}`} alt={`Slide ${image}`} className="w-full h-auto" />
            </div>
          ))}
        </ImageCarousel>
        <div className='scroll' onClick={() => scrollToNextSection('ownApp')}>
          <img src="/icons/down-arrow.svg" alt="Down Arrow" height={40} className="arrow-down" />
        </div>
        <div className='intro cinematic-intro'>
          <div className='flex-row-center mt-32 mb-6 cursor-pointer' onClick={() => setIsRenderOpen(true)}>
            <img src="/icons/playbtn.svg" className='mr-2 playbtn' alt="" />
            <p className='border-white border-b-2 intro_title uppercase'>{t('section6.mediaTitle')}</p>
          </div>
        </div>
        <p className='text-p-m mt-3 uppercase'>{t('section6.label')}</p>

      </div>
    </div>
  )

}

export default Renders
