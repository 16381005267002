import React, { useState, useEffect } from 'react';
import ImageCarousel from "./ImageCarousel";
const images = [
  { path: '/images/hbhotel.png', title: 'Sheraton Hervey Bay', link: 'https://hbhotel.metaluxe.com.au/' },
  { path: '/images/vista.png', title: '26 Vista, Surfers Paradise', link: 'https://vista.metaluxe.com.au/' },
  { path: '/images/avid.png', title: 'Avid Vantage Hervey Bay', link: 'https://avidvantage.metaluxe.com.au/' },
  { path: '/images/harbourshores.png', title: 'Harbour Shores, Gold Coast', link: 'https://harbourshores.metaluxe.com.au/' },
  { path: '/images/yves.png', title: 'Yves, Meraid Beach', link: 'https://yves.metaluxe.com.au/' },
  { path: '/images/florian.png', title: 'Florian, Gold Coast', link: 'https://florian.metaluxe.com.au/' },
  { path: '/images/nera.png', title: 'Nera, Chevron Island', link: 'https://nera.metaluxe.com.au/' },
  { path: '/images/108jukes.png', title: 'Jukes St, Melbourne', link: 'https://108jukes.metaluxe.com.au/' },
  { path: '/images/rivaoffices.png', title: 'Riva Offices, Gold Coast', link: 'https://rivaoffices.metaluxe.com.au/' },
  { path: '/images/pahealthconnect.png', title: 'PA Health, Brisbane', link: 'https://pahealthconnect.metaluxe.com.au/' },
  { path: '/images/eternity.png', title: 'Eternity, Broadbeach', link: 'https://eternity.metaluxe.com.au/' },
];

const YouOwnApp = ({ show, t }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setIsVisible(true), 80);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <div className={`fade-in-section relative text-center ${isVisible ? 'show' : ''}`}>
      <h1 className='header text-2xl md:text-4xl text-black tracking-widest text-center'>
        {t('section7.header')}
      </h1>
      <div className='section-entry'>
        <p className="text-p-s py-8 px-0 md:px-8">
          {t('section7.description')}
        </p>
      </div>

      {/* <Link to="/offer" reloadDocument className="btn rounded-bl-xl rounded-tr-xl" >
        ENQUIRE NOW
      </Link> */}
      <div className="mt-2" id="custom-apps">
        <ImageCarousel slideCount={2} >
          {images.map((image, index) => (
            <div key={index} className='flex-center relative'>
              <a href={image.link} target='_blank' rel="noreferrer" aria-label={image.title}>
                <img src={image.path} alt={`Slide ${index}`} className="w-full h-auto object-cover cover-image" />
                {image.title &&
                  <p className='text-p-m mt-3 uppercase'>{image.title}
                  </p>
                }
                <div className='intro your-own-app-intro'>
                  <div className='flex-row-center cursor-pointer'>
                    <img src="/icons/playbtn.svg" className='mr-2 playbtn' alt="" />
                    <p className='border-white border-b-2 intro_title uppercase'>{t('section7.mediaTitle')}</p>
                  </div>
                </div>
              </a>

            </div>
          ))}
        </ImageCarousel>
      </div>
    </div>
  )
}
export default YouOwnApp
