import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ t }) => {
  return (
    <footer className="py-8 bg-black text-white text-center relative">
      <p className='terms pb-16'>
        <Link to="/terms-and-conditions" reloadDocument>{t('termsAndCondition')}</Link>
        /
        &nbsp;
        <Link to="/privacy-policy" reloadDocument>{t('privacy')}</Link>
      </p>
      <div className="banner_slide flex-center">
        <p className='text-p'>
          <Link to="/offer" reloadDocument>{t('enquireNow')}</Link>
        </p>
      </div>
      <div className='flex-around pt-16 px-24'>
        <img src="/images/logo.png" className='h-14 mb-5' alt="" />
      </div>
      <p className="text-sm copyright">
        &copy;<span id="year">2024</span> Metaluxe. {t('allRightsReserved')}.
      </p>
    </footer>
  );
};

export default Footer;
