import React, { useState } from 'react';

const Tooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div
      className="relative flex items-center w-full text-center"
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {children}
      {showTooltip && (
        <div className="absolute w-full bottom-full mb-2 px-2 py-1 bg-gray-800 text-white text-sm rounded shadow-lg transform -translate-x-1/2 left-1/2">
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
