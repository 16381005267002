import React, { useState, useEffect } from "react";

const VirtualTour = ({ setIsTourOpen, show, t, scrollToNextSection }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setIsVisible(true), 80);
      return () => clearTimeout(timer);
    }
  }, [show]);

  const handleOnClickTour = () => {
    setIsTourOpen(true)
    let video = document.getElementById('virtual-tour-demo');
    video.pause();
    video.removeAttribute('controls');

  }
  return (
    <div className={`fade-in-section relative text-center  ${isVisible ? 'show' : ''}`}>
      <h1 className='header text-2xl md:text-4xl text-black tracking-widest uppercase'>
        {t('section5.header')}
      </h1>
      <div className='section-entry'>
        <p className="text-p-s px-0 pt-10 pb-12 md:px-8">
          {t('section5.description')}
        </p>
      </div>

      {/* <Link to="/offer" reloadDocument className="btn rounded-bl-xl rounded-tr-xl" id='360-tour'>
        ENQUIRE NOW
      </Link> */}

      <div className='relative' id='360-tour'>
        <video autoPlay={true} playsInline={true} muted={true} controls={false} loop={true} style={{ height: '100%', width: '100%' }} id="virtual-tour-demo">
          <source src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/virtual-tour-demo.mp4`} type="video/mp4" />
        </video>
        <div className='scroll' onClick={() => scrollToNextSection('render')}>
          <img src="/icons/down-arrow.svg" alt="Down Arrow" height={40} className="arrow-down" />
        </div>
        <p className='text-p-m mt-3 uppercase'>{t('section5.label')}</p>

        <div className='intro virtual-tour-intro'>
          <div className='flex-row-center mt-32 md:mt-36 mb-6 cursor-pointer' onClick={() => handleOnClickTour()}>
            <img src="/icons/playbtn.svg" className='mr-2 playbtn' alt="" />
            <p className='border-white border-b-2 intro_title'>{t('section5.mediaTitle')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
export default VirtualTour
