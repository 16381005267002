
import React from "react";

const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="/icons/right-arrow.svg" alt="Next" className="custom-arrow" />
    </div>
  );
};

const SwapNextArrow = (props) => {
  const { onClick } = props;
  return (
    <div div className="slick-arrow slick-prev swap-slick-prev" onClick={onClick}>
      <img src="/icons/left-arrow.svg" alt="Prev" className="custom-arrow" />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <img src="/icons/left-arrow.svg" alt="Previous" className="custom-arrow" />
    </div>
  );
};

const SwapPrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className="slick-arrow slick-next swap-slick-next" onClick={onClick}>
      <img src="/icons/right-arrow.svg" alt="Next" className="custom-arrow" />
    </div>
  );
};

export { NextArrow, PrevArrow, SwapNextArrow, SwapPrevArrow }
