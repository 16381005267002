import React, { useRef, useEffect } from 'react';

const Toast = ({ onClose, message, t }) => {
  const modalRef = useRef(null);

  // Close the modal when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className="fixed inset-0 flex items-end justify-center z-50">
      <div
        ref={modalRef}
        className="relative bg-green-300 rounded-lg shadow-lg w-full md:w-1/4  md:h-1/4 mb-4"
      >
        <div className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl cursor-pointer">
          {/* <button
            className="text-white hover:text-gray-400"
            onClick={onClose}
          >
            ✕
          </button> */}
        </div>
        <div className="text-center p-5">
          <p className="text-2xl pb-4">{t('thankYou')} !!</p>
          <p className="text-xl leading-8">
            {message}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Toast;
