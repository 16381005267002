import Slider from 'react-slick';
import { NextArrow, PrevArrow, SwapNextArrow, SwapPrevArrow } from './custom-react-slick/Arrow';

const ImageCarousel = ({ slideCount, children, setSlideIndex, isNavigateSwap = false }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: slideCount,
    // cssEase: 'ease-out',
    slidesToScroll: 1,
    autoplay: true,
    rtl: isNavigateSwap,
    autoplaySpeed: isNavigateSwap ? 6500 : 6500,
    nextArrow: isNavigateSwap ? <SwapNextArrow /> : <NextArrow />,
    prevArrow: isNavigateSwap ? <SwapPrevArrow /> : <PrevArrow />,

    beforeChange: (current, next) => setSlideIndex && setSlideIndex(next),


    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {children}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
