import React, { useEffect, useState } from 'react';

const Jser = ({ setIsModalOpen, show, t, scrollToNextSection }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setIsVisible(true), 80);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <div className={`fade-in-section relative text-center ${isVisible ? 'show' : ''}`}>
      <h1 className='header text-2xl md:text-4xl text-black tracking-widest text-center uppercase'>{t('section0.header')}</h1>
      <div className='section-entry'>
        <p className="text-p-s pt-8 pb-4 px-0 md:px-16">
          {t('section0.description')}
        </p>
      </div>

      {/* <Link to="/offer" reloadDocument className="btn rounded-bl-xl rounded-tr-xl" id="vision-to-life">
        ENQUIRE NOW
      </Link> */}
      <div className="mt-16" id="jennifer">
        <video autoPlay={true} playsInline={true} muted={true} controls={false} loop={true} style={{ height: '100%', width: '100%' }}>
          <source src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/jennifer.mp4`} type="video/mp4" />
        </video>
        <div className='scroll' onClick={() => scrollToNextSection('vision')}>
          <img src="/icons/down-arrow.svg" alt="Down Arrow" height={40} className="arrow-down" />
        </div>
      </div>
      <div className='intro vision-to-life-intro'>
        <p className='border-white mt-32 md:mt-36 text-2xl md:text-5xl uppercase text-white'>{t('commingSoon')}</p>

        <div className='flex-row-center mt-0 md:mt-20 mb-6 cursor-pointer'>
          <img src="/icons/playbtn.svg" className='mr-2 playbtn' alt="" />
          <p className='border-white border-b-2 intro_title uppercase'>{t('section0.mediaTitle')}</p>
        </div>
      </div>
      <p className='text-p-m mt-3 uppercase'>{t('section0.label')}</p>
    </div>
  )
}

export default Jser
