import React, { useState, useEffect } from "react";

const Animation = ({ setIsVideoOpen, show, t, scrollToNextSection }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => setIsVisible(true), 80);
      return () => clearTimeout(timer);
    }
  }, [show]);

  return (
    <div className={`fade-in-section relative text-center ${isVisible ? 'show' : ''}`}>
      <h1 className='header text-2xl md:text-4xl text-black tracking-widest text-center'>{t('section3.header')}</h1>
      <div className='section-entry'>
        <p className="text-p-s px-0 pt-10 pb-12 md:px-8">
          {t('section3.description')}
        </p>
      </div>
      {/* 
      <Link to="/offer" reloadDocument className="btn rounded-bl-xl rounded-tr-xl" id="cinematic">
        ENQUIRE NOW
      </Link> */}
      <div id="cinematic">
        <video autoPlay={true} playsInline={true} muted={true} controls={false} loop={true} style={{ height: '100%', width: '100%' }}>
          <source src={`${process.env.REACT_APP_BASE_URL}/theme/dashboard/showcase.mp4`} type="video/mp4" />
        </video>
        <div className='scroll' onClick={() => scrollToNextSection('virtualTour')}>
          <img src="/icons/down-arrow.svg" alt="Down Arrow" height={40} className="arrow-down" />
        </div>
      </div>
      <div className='intro cinematic-intro'>
        <div className='flex-row-center mt-36 mb-6 cursor-pointer' onClick={() => setIsVideoOpen(true)}>
          <img src="/icons/playbtn.svg" className='mr-2 playbtn' alt="" />
          <p className='border-white border-b-2 intro_title uppercase'>{t('section3.mediaTitle')}</p>
        </div>
      </div>
      <p className='text-p-m mt-3 uppercase'>{t('section3.label')}</p>
    </div>
  )
}
export default Animation;
