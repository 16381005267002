import React from 'react';

const Modal = ({ children, isOpen, setIsOpen }) => {
  const closeModal = () => setIsOpen(false);

  return (
    <div>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-lg w-[90%]  h-[90%]">
            <div className="absolute top-1 md:top-4 right-4 text-gray-500 hover:text-gray-700 text-2xl cursor-pointer">
              <button
                className="text-white hover:text-gray-400"
                onClick={closeModal}
              >
                ✕
              </button>
            </div>

            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
