import React, { useEffect, useState, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from './components';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isHeaderColorActive, setIsHeaderColorActive] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsHeaderColorActive(false);
      } else {
        setIsHeaderColorActive(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Layout
          toggleSidebar={toggleSidebar}
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
          isHeaderColorActive={isHeaderColorActive}
        />
      </Router>
    </Suspense>
  );
}

export default App;
