import React from 'react';

function PrivacyPolicy() {
  return (
    <div className="container px-5 md:px-20 py-20">
      <h1 className="text-2xl md:text-3xl">Privacy Policy</h1>
      <div className="flex flex-col gap-10 pt-4 ">
        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Introduction</h3>
          <p>Metaluxe is committed to protecting the privacy of all visitors to our website, <a href="https://www.metaluxe.com.au">www.Metaluxe.com.au</a>, This privacy policy outlines the information we collect, how it is used, and the steps we take to ensure it is protected.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Information Collection and Use
          </h3>
          <p>Metaluxe collects information from visitors to our website in two ways: through the use of cookies, and through the "Enquire Now" form.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Cookies</h3>
          <p>
            Cookies are small text files stored on a visitor's device that allow us to remember if a user is logged in. We use cookies to enhance the user experience on our website and to provide a more personalized experience. You can disable cookies in your browser settings if you do not wish for us to collect this information.

          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Enquire Now Form</h3>
          <p>
            When you submit a message through the "Enquire Now" form, we collect and store your name, email address, and mobile number. This information is used to respond to your inquiry and to communicate with you about our products and services. We may also use this information to improve our website and marketing efforts.

          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Information Protection</h3>
          <p>
            Metaluxe takes the protection of your information seriously. We have implemented appropriate technical and organizational measures to secure the information we collect. Access to this information is limited to authorized personnel only, and it is only used for the purposes outlined in this privacy policy.

          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Your Rights</h3>
          <p>
            Metaluxe may update this privacy policy from time to time. We will notify you of any changes by posting the updated policy on our website.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <h3 className="text-2xl">Contact Us</h3>
          <p>
            If you have any questions or concerns about this privacy policy orthe information we collect, please contact us through the "Enquire Now" form on our website, or by emailing us at “info@metaluxe.com.au”.
          </p>
        </div>

        <p>Last Revised: October 23, 2024</p>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
